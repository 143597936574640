import http from "@/utils/http";

// 可答题列表
export function respondentList(data) {
  return http({
    method: "post",
    url: "/respondent/list",
    data,
  });
}

// 是否可以答题
export function questionnaireCheckPermission(data) {
  return http({
    method: "post",
    url: "/questionnaire/checkPermission",
    data,
  });
}

// 是否可以答题
export function questionnaireCheckNext(data) {
  return http({
    method: "post",
    url: "/questionnaire/checkNext",
    data,
  });
}

// 题目-查询
export function subjectQueryByQuestionnaireIdList(data) {
  return http({
    method: "post",
    url: "/subject/queryByQuestionnaireIdList",
    data,
  });
}

// 根据ID查询
export function questionnaireFindById(data) {
  return http({
    method: "post",
    url: "/questionnaire/findById",
    data,
  });
}

// 返回用户列表
export function userQueryByUserIds(data) {
  return http({
    method: "post",
    url: "/user/queryByUserIds",
    data,
  });
}

// 为0新增问卷
export function questionnaireReplyAddOrUpdate(data) {
  return http({
    method: "post",
    url: "/questionnaireReply/addOrUpdate",
    data,
  });
}

// 不为0新增问卷
export function questionnaireSubjectList(data) {
  return http({
    method: "post",
    url: "/questionnaireSubject/list",
    data,
  });
}

// 答题完成
export function questionnaireReplyCompleteTime(data) {
  return http({
    method: "post",
    url: "/questionnaireReply/completeTime",
    data,
  });
}

//问卷题目回复 - 题目 - 新增编辑答案
export function questionnaireSubjectAddOrUpdate(data) {
  return http({
    method: "post",
    url: "/questionnaireSubject/addOrUpdate",
    data,
  });
}

//查询被评分部门得分
export function queryQuestionnaireToRater(data) {
  return http({
    method: "post",
    url: "/questionnaire/queryQuestionnaireToRater",
    data,
  });
}