<template>
  <div class="box">
    <div class="person">
      <div class="center">
        <div class="title">{{ title }}</div>
        <div class="specific">
          <div class="con" v-for="(item, ind) in list" :key="ind">
            <div v-if="item.type == '1'">
              <div class="one">
                <div class="left">
                  <p>
                    {{ item.departmentScore ? item.departmentScore : "暂无" }}
                  </p>
                  <p>部门得分</p>
                </div>
                <div class="right">
                  <p>{{ item.personScore ? item.personScore : "暂无" }}</p>
                  <p>本人得分</p>
                </div>
              </div>
              <div class="two">
                <div class="two-title">
                  {{ item.subject }}
                </div>
                <div class="table-one">
                  <div class="table-sala">维度得分</div>
                  <el-table :data="item.dimensions" style="width: 100%" border>
                    <el-table-column prop="name" label="维度名称">
                    </el-table-column>
                    <el-table-column
                      prop="departmentScore"
                      label="部门维度得分"
                    >
                    </el-table-column>
                    <el-table-column prop="personScore" label="本人维度得分">
                    </el-table-column>
                  </el-table>
                </div>
                <div class="table-two">
                  <div class="table-sala">行标题得分</div>
                  <el-table :data="item.titles" style="width: 100%" border>
                    <el-table-column prop="name" label="维度名称">
                    </el-table-column>
                    <el-table-column prop="titleName" label="行标题名称">
                    </el-table-column>
                    <el-table-column
                      prop="departmentScore"
                      label="部门题目得分"
                    >
                    </el-table-column>
                    <el-table-column prop="personScore" label="本人题目得分">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div v-if="item.type == '4'">
              <div class="opinion">
                <div class="title">{{ item.subject }}</div>
                <div class="con-html" v-if="item.remarks && item.remarks.length > 0">
                  <div v-for="(v, d) in item.remarks" :key="d">
                    {{ v }}
                  </div>
                </div>
                <div v-else class="con-html">暂无</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer">
        <van-button class="handle-btn" @click="$router.go(-1)">
          关闭
        </van-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { queryQuestionnaireToRater } from "@/apis/mobileApi";
export default {
  data() {
    return {
      textarea: "",
      title: "",
      list: [],
    };
  },
  methods: {
    getList() {
      queryQuestionnaireToRater({ id: this.$route.query.id }).then((data) => {
        console.log(data);
        this.title = data.data.department;
        this.list = data.data.subjects;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
$zoom: 1020 / 375;
@media screen and (max-width: 600px) {
  .person {
    width: 100%;
    background: rgba(247, 249, 250, 1);
    font-size: 20px * $zoom;
    height: 100vh;
  }
}
@media screen and (min-width: 600px) {
  .person {
    width: 406px * $zoom;
    background: rgba(247, 249, 250, 1);
    font-size: 20px * $zoom;
    height: 100vh;
  }
}
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: rgba(247, 249, 250, 1);
}
.person {
  display: flex;
  flex-direction: column;
  padding-top: 10px * $zoom;
}
.center {
  //   flex: 1;
  // height: calc(100vh - 70px * $zoom);
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .title {
    font-weight: 600;
    font-size: 16px * $zoom;
    background: #fff;
    position: relative;
    padding: 15px * $zoom 15px * $zoom 0;
    &::before {
      content: "";
      position: absolute;
      left: 0px * $zoom;
      width: 4px * $zoom;
      height: 17px * $zoom;
      background: #008755;
    }
  }
  .con {
    background: #fff;
    margin-bottom: 10px * $zoom;
    .one {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px * $zoom;
      padding: 15px * $zoom 15px * $zoom 0px * $zoom;
      .left,
      .right {
        width: 80px * $zoom;
        height: 80px * $zoom;
        background: #dff5ec;
        border-radius: 50%;
        text-align: center;
        p:nth-child(1) {
          color: rgba(78, 162, 124, 1);
          font-size: 22px * $zoom;
          margin-top: 15px * $zoom;
        }
        p:nth-child(2) {
          color: rgba(51, 51, 51, 1);
          font-size: 12px * $zoom;
          margin-top: 5px * $zoom;
        }
      }
    }
    .two {
      padding:0px * $zoom 15px * $zoom 15px * $zoom;
      .two-title {
        text-align: center;
        font-size: 16px * $zoom;
        font-weight: 550;
      }
      .table-one,
      .table-two {
        margin-top: 15px * $zoom;
        .table-sala {
          width: 100%;
          line-height: 35px * $zoom;
          border: 1px solid #ebeef5;
          border-bottom: none;
          font-size: 12px * $zoom;
          text-align: center;
        }
      }
    }
  }
  .con:nth-last-child(1) {
    margin-bottom: 0px * $zoom;
  }
  .opinion {
    background: #fff;
    margin-left: -15px * $zoom;
    padding: 0px * $zoom 15px * $zoom 10px * $zoom;
    .con-html {
      width: 96%;
      background: rgba(247, 249, 250, 1);
      // margin-left: 8px * $zoom;
      font-size: 12px * $zoom;
      margin: 15px * $zoom 15px * $zoom;
      padding: 10px * $zoom;
      line-height: 24px * $zoom;
    }
    ::v-deep {
      .el-textarea {
        margin: 8px * $zoom 0;
        width: 96%;
        margin-left: 8px * $zoom;
        .el-textarea__inner {
          background: rgba(247, 249, 250, 1);
        }
      }
    }
  }
}
.footer {
  width: 100%;
  height: 60px * $zoom;
  background: #fff;
  margin-top: 10px * $zoom;
  display: flex;
  align-items: center;
  justify-content: center;
  .handle-btn {
    width: 80%;
    height: 42px * $zoom;
    border-radius: 5px * $zoom;
    background: rgba(78, 162, 124, 1);
    color: #fff;
    font-size: 16px * $zoom;
  }
}
::v-deep {
  thead {
    .cell {
      color: rgba(78, 162, 124, 1);
    }
  }
  .cell {
    text-align: center;
    font-size: 6px * $zoom;
  }
}
</style>
